import React from 'react'
import { graphql } from 'gatsby'
import v4 from 'uuid'
import Layout from '../components/layout'
import Ast from '../components/ast'
import Seo from '../components/seo'
import ServiceCard from '../components/service-card'
import Heading from '../components/page-heading'
import Heading2 from '../components/elements/heading2'
import Heading3 from '../components/elements/heading3'

export default ({ data, pageContext }) => {
  const servicePost = data.contentfulServicePost
  const otherServices = pageContext.otherServices
  const { title, body, subservices } = servicePost.service
  const htmlAst = body.childMarkdownRemark.htmlAst

  return (
    <Layout customSeo={`true`}>
      <Seo servicePost={{ ...servicePost }} />
      <Heading>{title}</Heading>
      <Ast ast={htmlAst} />

      <section>
        <Heading2>Subservicies of {title}</Heading2>

        {subservices.map(({ title, body }) => {
          const htmlAst = body.childMarkdownRemark.htmlAst

          return (
            <div key={v4()}>
              <Heading3>{title}</Heading3>
              <Ast ast={htmlAst} />
            </div>
          )
        })}
      </section>

      <section>
        <Heading2>Other services</Heading2>

        {otherServices.map(
          ({
            node: {
              title,
              slug,
              service: {
                description,
                path: { url },
              },
            },
          }) => {
            const htmlAst = description.childMarkdownRemark.htmlAst

            return <ServiceCard key={v4()} title={title} url={`${url}/${slug}`} ast={htmlAst} />
          }
        )}
      </section>
    </Layout>
  )
}

export const PageQuery = graphql`
  query ServicePost($slug: String!) {
    contentfulServicePost(slug: { eq: $slug }) {
      title
      slug
      service {
        price
        path {
          url
          label
        }
        title
        body {
          childMarkdownRemark {
            html
            htmlAst
            excerpt
          }
        }
        subservices {
          title
          body {
            childMarkdownRemark {
              html
              htmlAst
            }
          }
        }
      }
    }
  }
`
